import { environment } from '../../environments/environment';


export const urlConstant = {
  Auth: {
    Login: environment.APIUrl + 'EaseRent/login',
    Registration: environment.APIUrl + 'EaseRent/registration',
    ActivateAccount: environment.APIUrl + 'account-verify',
    ForgetPassword: environment.APIUrl + 'EaseRent/forgot-password',
    ResetPassword: environment.APIUrl + 'account-verify',
    updatePassword: environment.APIUrl + 'EaseRent/updatePassword',
    updateUser: environment.APIUrl + 'EaseRent/updateUser',
    addContactUs: environment.APIUrl + 'EaseRent/addContactUs',
    getUserDetail: environment.APIUrl + 'EaseRent/getUserDetail',
    resetUserRassword: environment.APIUrl + 'EaseRent/reset-password',
    validToken: environment.APIUrl + 'EaseRent/validToken/',
    getUserDetailByUserId: environment.APIUrl + 'EaseRent/getUserDetailByUserId',
    addCompanyDetail: environment.APIUrl + 'EaseRent/addCompanyDetail',
    getCompanyDetail: environment.APIUrl + 'EaseRent/getCompanyDetail',
    getUserByEmail: environment.APIUrl + 'EaseRent/getUserByEmail',
    ResendVerificationLink: environment.APIUrl + 'EaseRent/resend-verify-link',
    UpdateUserByUserId: environment.APIUrl + 'EaseRent/updateUserByUserId',
    getAuthInspection: environment.APIUrl + 'EaseRent/inspection/url/byId',
    approvedInspectionStatus: environment.APIUrl + 'EaseRent/inspection/approvedStatus',
    addInspectionOpenUrl: environment.APIUrl + 'EaseRent/inspection/url',
    inspectionRepairStatus : environment.APIUrl + 'EaseRent/inspection/inspectionRepairStatus',
    updateModules: environment.APIUrl + 'EaseRent/updateModules',
    getInvoiceOpenUrl: environment.APIUrl + 'EaseRent/invoice/getInvoiceOpenUrl',
    getInvitedUser: environment.APIUrl + 'EaseRent/getInvitedUser',
    userListByProperty: environment.APIUrl + 'EaseRent/userListByProperty',
  },
  Property: {
    Add: environment.APIUrl + 'EaseRent/addProperty',
    bulkAssign: environment.APIUrl + 'EaseRent/properties/bulkAssign',
    AddDetail: environment.APIUrl + 'EaseRent/addPropertyDetails',
    ByUserId: environment.APIUrl + 'EaseRent/getPropertyByUserId',
    GetPropertyByPropertyId: environment.APIUrl + 'EaseRent/getPropertyByPropertyId',
    SearchProperties: environment.APIUrl + 'EaseRent/searchProperties',
    ShareProperties: environment.APIUrl + 'EaseRent/shareProperties',
    SharePropertiesByEmail: environment.APIUrl + 'EaseRent/sharePropertiesByEmail',
    SharePropertiesByWhatsApp: environment.APIUrl + 'EaseRent/sharePropertiesByWhatsApp',
    WhatsappNow: environment.APIUrl + 'EaseRent/whatsappNow',
    UploadDocumentPropertyByPropertyId: environment.APIUrl + 'EaseRent/uploadFile',
    DeletePropertyByPropertyId: environment.APIUrl + 'EaseRent/deleteProperty',
    GetDocumentByPropertyId: environment.APIUrl + 'EaseRent/getDocumentByPropertyId',
    getDocumentByDocumentId: environment.APIUrl + 'EaseRent/getDocumentByDocumentId',
    DeleteDocumentByDocumentId: environment.APIUrl + 'EaseRent/deleteDocument',
    AddPropertyUserInvitation: environment.APIUrl + 'EaseRent/addPropertyUserInvitation',
    updateRentOccupiedDetail: environment.APIUrl + 'EaseRent/updateRentOccupiedDetail',
    updateProperty: environment.APIUrl + 'EaseRent/updateProperty',
    updatePropertyCoverImage: environment.APIUrl + 'EaseRent/updatePropertyCoverImage',
    getPropertyUserInvitationByInvitationId: environment.APIUrl + 'EaseRent/getPropertyUserInvitationByInvitationId',
    getPropertyUserInvitationByPropertyId: environment.APIUrl + 'EaseRent/getPropertyUserInvitationByPropertyId',
    getUserDetailByPropertyId: environment.APIUrl + 'EaseRent/getUserDetailByPropertyId',
    im_pay_request: environment.APIUrl + 'EaseRent/im_pay_request',
    resetPropertyData: environment.APIUrl + 'EaseRent/resetPropertyData/',
    addShareProperty: environment.APIUrl + 'EaseRent/addSharedProperty',
    getPropertyShare: environment.APIUrl + 'EaseRent/getPropertyShare',
    addAppointment: environment.APIUrl + 'EaseRent/addAppointment',
    getAppointment: environment.APIUrl + 'EaseRent/getAppointment',
    getAllAppointment: environment.APIUrl + 'EaseRent/getAllAppointment',
    getAllWebAppointment: environment.APIUrl + 'EaseRent/getAllWebAppointment',
    getPropertyByPartnerId: environment.APIUrl + 'EaseRent/getPropertyByPartnerId',
    searchPropertiesForWebsite: environment.APIUrl + 'EaseRent/searchPropertiesForWebsite',
    saveShareUserDetails: environment.APIUrl + 'EaseRent/saveShareUserDetails',
    uploadDoc: environment.APIUrl + 'EaseRent/uploadDoc',
    getShareUserDetails: environment.APIUrl + 'EaseRent/getShareUserDetails',
    addPropertyCount: environment.APIUrl + 'EaseRent/addPropertyCount',
    getTenantDetailsByPropertyId: environment.APIUrl + 'EaseRent/getTenantDetailsByPropertyId',
    getUserByPropertyId: environment.APIUrl + 'EaseRent/getUserByPropertyId',
    sharePropertiesByInquiryEmail: environment.APIUrl + 'EaseRent/sharePropertiesByInquiryEmail',
    sharePropertyByInquiryWhatsApp: environment.APIUrl + 'EaseRent/sharePropertyByInquiryWhatsApp',
    getPropertyForLead: environment.APIUrl + 'EaseRent/getPropertyForLead',
    addShareCount: environment.APIUrl + 'EaseRent/addShareCount',
    getRentOccupiedProperies: environment.APIUrl + 'EaseRent/getRentOccupiedProperies',
    getAllLocalities: environment.APIUrl + 'EaseRent/getAllLocalities',
    savePropertyNote:  environment.APIUrl + 'EaseRent/propertyNote',
    updatePropertyNote:  environment.APIUrl + 'EaseRent/propertyNote',
    getPropertyNote:  environment.APIUrl + 'EaseRent/propertyNote',
    getAllCities:  environment.APIUrl + 'EaseRent/getAllCities',
    deletePropertyKey : environment.APIUrl + 'EaseRent/propertyKey',
    getAllPropertyKeyDes : environment.APIUrl + 'EaseRent/propertyKeyDes',
    getAllTenantAndOwnerProperty : environment.APIUrl + 'EaseRent/getAll/filter',
    updateBulkProperties: environment.APIUrl + 'EaseRent/updateBulkProperties',
    getPropertyIdsforImage: environment.APIUrl + 'EaseRent/getPropertyIdsforImage',
    getTenantByPropertyId: environment.APIUrl + 'EaseRent/getTenantByPropertyId',
    getBudgetPriceList: environment.APIUrl + 'EaseRent/getBudgetPriceList',
    getAllPropertyForMaintenanceManager: environment.APIUrl + 'EaseRent/getAllPropertyForMaintenanceManager',
    getPropertiesByUserId: environment.APIUrl + 'EaseRent/getPropertiesByUserId',
    getDropDownList: environment.APIUrl + 'EaseRent/getDropDownList',
    getPropertyByCustomerUserId: environment.APIUrl + 'EaseRent/getPropertyByCustomerUserId',
  },
  RentPayment: {
    AddBankAccountDetails: environment.APIUrl + 'EaseRent/addBankAccountDetails',
    GetUserBankAccountDetailsByUserId: environment.APIUrl + 'EaseRent/getUserBankAccountDetailsByUserId',
    getDefaultPMUserBankAccountDetails: environment.APIUrl + 'EaseRent/getDefaultPMUserBankAccountDetails',
    GetPaymentDetailsByPropertyId: environment.APIUrl + 'EaseRent/getPaymentDetailsByPropertyId',
    GetPaymentDetailsByPropertyIdAndUserId: environment.APIUrl + 'EaseRent/getPaymentDetailsByPropertyIdAndUserId',
    AddUserDetailsByPropertyId: environment.APIUrl + 'EaseRent/addUserDetailsByPropertyId',
    AddPaymentDetails: environment.APIUrl + 'EaseRent/addPaymentDetails',
    AddCreditCardDetails: environment.APIUrl + 'EaseRent/addCreditCardDetails',
    getPropertyUserInvitationByInvitationId: environment.APIUrl + 'EaseRent/getPropertyUserInvitationByInvitationId',
    deleteBankAccountDetails: environment.APIUrl + 'EaseRent/deleteBankAccountDetails',
    getUserScheduledPaymentByUserId: environment.APIUrl + 'EaseRent/getUserScheduledPaymentByUserId',
    getUserScheduledPaymentByPropertyId: environment.APIUrl + 'EaseRent/getUserScheduledPaymentByPropertyId',
    pmPaidRequest: environment.APIUrl + 'EaseRent/pm_paid_request',
    histories: environment.APIUrl + 'EaseRent/im_pay_history?page=1',
    getPaymentHistoryByPropertyId: environment.APIUrl + 'EaseRent/getPaymentHistoryByPropertyId',
    addRentDetails: environment.APIUrl + 'EaseRent/addRentDetails',
    UpdatePropertyPaymentDetails: environment.APIUrl + 'EaseRent/updatePropertyPaymentDetails',
    updatePropertyOnRentAgreement: environment.APIUrl + 'EaseRent/updatePropertyOnRentAgreement',
    updatePropertyOnSecurityDeposit: environment.APIUrl + 'EaseRent/updatePropertyOnSecurityDeposit',
    deleteUserByUserId: environment.APIUrl + 'EaseRent/deleteUserByUserId',
    getOwnerPdf: environment.APIUrl + 'EaseRent/getOwnerPdf',
    getRentReceipt: environment.APIUrl + 'EaseRent/getRentReceipt',
    getDataForFilter: environment.APIUrl + 'EaseRent/getDataForFilter',
    downloadPaymentStatement: environment.APIUrl + 'EaseRent/downloadPaymentStatement',
    byUserDetailId: environment.APIUrl + 'EaseRent/byUserDetailId',
    deletePaymentByScheduleId : environment.APIUrl + 'EaseRent/deletePaymentByScheduleId',
    editPropertyPaymentDetails : environment.APIUrl + 'EaseRent/editPropertyPaymentDetails',
    addMarkPayment: environment.APIUrl + 'EaseRent/addMarkPayment',
    UserScheduledPaymentOpenApi: environment.APIUrl + 'EaseRent/getUserScheduledPayment/openId',
  },
  PropertyStatus: {
    StatusAdd: environment.APIUrl + 'EaseRent/status/add',
    StatusGet: environment.APIUrl + 'EaseRent/status/get/',
    getBankingAccuntType: environment.APIUrl + 'EaseRent/status/getBankingAccuntType',
    getPaymentType:environment.APIUrl + 'EaseRent/status/getPaymentType',
    getParamByType:environment.APIUrl + 'EaseRent/status/getParamByType',
  },
  MoveIn: {
    addPropertyMoveInUserInvitation: environment.APIUrl + 'EaseRent/addMoveInUserInvitation',
    moveInAddAttachment: environment.APIUrl + 'EaseRent/moveInAddAttachment',
    moveInDeleteAttachment: environment.APIUrl + 'EaseRent/moveInDeleteAttachment',
    getMoveInTaskByPropertyType: environment.APIUrl + 'EaseRent/getMoveInTaskByPropertyType',
    startMoveIn: environment.APIUrl + 'EaseRent/startMoveIn/',
    getMoveInSummaryByMoveInDetailsId: environment.APIUrl + 'EaseRent/getMoveInSummaryByMoveInDetailsId/',
    getMoveInDetailsByMoveInDetailsId: environment.APIUrl + 'EaseRent/getMoveInDetailsByMoveInDetailsId/',
    getMoveInDetailsByInvitationId: environment.APIUrl + 'EaseRent/getMoveInDetailsByInvitationId/',
    moveInSubmitData: environment.APIUrl + 'EaseRent/moveInSubmitData',
    moveInAddComment: environment.APIUrl + 'EaseRent/moveInAddComment',
    getMoveOutSummaryView: environment.APIUrl + 'EaseRent/getMoveOutSummaryView',
  },
  PropertyDescription: {
    PropertyDescriptionAdd: environment.APIUrl + 'EaseRent/propertyDetailsDesc/add/',
    PropertyDescriptionGet: environment.APIUrl + 'EaseRent/propertyDetailsDesc/get/',
    getInventoryMaster: environment.APIUrl + 'EaseRent/propertyDetailsDesc/getInventoryMaster',
    savePropertyInventoryList: environment.APIUrl + 'EaseRent/propertyDetailsDesc/savePropertyInventoryList',
    getPropertyInventory: environment.APIUrl + 'EaseRent/propertyDetailsDesc/getPropertyInventory',
    deleteInventoryItem: environment.APIUrl + 'EaseRent/propertyDetailsDesc/deleteInventoryItem',
    deleteMultipleInventoryItems: environment.APIUrl + 'EaseRent/propertyDetailsDesc/deleteMultipleInventoryItems',
    savePropertyInventory: environment.APIUrl + 'EaseRent/propertyDetailsDesc/savePropertyInventory',
    deleteInventorySection: environment.APIUrl + 'EaseRent/propertyDetailsDesc/deleteInventorySection',
    updateInventorySection: environment.APIUrl + 'EaseRent/propertyDetailsDesc/updateInventorySection',
    updatePropertyInventoryList: environment.APIUrl + 'EaseRent/propertyDetailsDesc/updatePropertyInventoryList',
    addAnotherSection: environment.APIUrl + 'EaseRent/propertyDetailsDesc/addAnotherSection',
    getInventorySectionsItems: environment.APIUrl + 'EaseRent/propertyDetailsDesc/getInventorySectionsItems/',
    addFlagForPropertyInventory: environment.APIUrl + 'EaseRent/propertyDetailsDesc/addFlagForPropertyInventory',
    getPropertyInventoryByDescOrder: environment.APIUrl + 'EaseRent/propertyDetailsDesc/getPropertyInventoryByDescOrder',
    
  },
  MoveOut: {
    addMoveOutUserInvitation: environment.APIUrl + 'EaseRent/addMoveOutUserInvitation',
    moveOutAddComment: environment.APIUrl + 'EaseRent/moveOutAddComment',
    moveOutAddAttachment: environment.APIUrl + 'EaseRent/moveOutAddAttachment',
    moveOutDeleteAttachment: environment.APIUrl + 'EaseRent/moveOutDeleteAttachment',
    startMoveOut: environment.APIUrl + 'EaseRent/startMoveOut',
    getMoveOutSummaryByMoveOutDetailsId: environment.APIUrl + 'EaseRent/getMoveOutSummaryByMoveOutDetailsId/',
    moveOutSubmitData: environment.APIUrl + 'EaseRent/moveOutSubmitData',
    getMoveOutTaskByPropertyType: environment.APIUrl + 'EaseRent/getMoveOutTaskByPropertyType',
  },

  BillUtility: {
    getParamMasterByParamType: environment.APIUrl + 'EaseRent/getParamMasterByParamType',
    addBill: environment.APIUrl + 'EaseRent/addBill',
    getBillByPropertyId: environment.APIUrl + 'EaseRent/getBillByPropertyId',
    updateBill: environment.APIUrl + 'EaseRent/updateBill',
    deleteBillByBillId: environment.APIUrl + 'EaseRent/deleteBillByBillId',
    addBillAttchment: environment.APIUrl + 'EaseRent/addAttachment',
    markPayment: environment.APIUrl + 'EaseRent/markPayment',
    allUsersByPropertyId: environment.APIUrl + 'EaseRent/allUsersByPropertyId',
    getDocumentByBillId: environment.APIUrl + 'EaseRent/getDocumentByBillId',
    deleteDocumnetById: environment.APIUrl + 'EaseRent/deleteDocumnetById',
    deleteDocumentByBill: environment.APIUrl + 'EaseRent/deleteDocumentByBill',
    addBillFromExcelData: environment.APIUrl + 'EaseRent/addBillFromExcelData',
  },
  SubscriptionPlan: {
    getAllSubscriptionDetailsAndUserSubscriptionDetailsByUserId: environment.APIUrl + 'EaseRent/getAllSubscriptionDetailsAndUserSubscriptionDetailsByUserId',
    addUserSubscriptionDetails: environment.APIUrl + 'EaseRent/addUserSubscriptionDetails',
    applyPromoCode: environment.APIUrl + 'EaseRent/applyPromoCode',
    getPromoDetailsByCode: environment.APIUrl + 'EaseRent/getPromoDetailsByCode',
  },
  User: {
    getUserDetailByUserId: environment.APIUrl + 'EaseRent/getUserDetailByUserId',
    searchKochiContact: environment.APIUrl + 'EaseRent/searchKochiContact',
    documentCategories: environment.APIUrl + 'EaseRent/document-categories',
    getUsersDetailByPropertyId: environment.APIUrl + 'EaseRent/getUsersDetailByPropertyId',
  },
  Dashboard: {
    ChartData: environment.APIUrl + 'EaseRent/dashboardChartData',
    AllComplaints: environment.APIUrl + 'EaseRent/getAllComplaints'
  },
  CMS: {
    getLabels: environment.APIUrl + 'EaseRent/cms/labels',
    getService: environment.APIUrl + 'EaseRent/cms/service',
    getProperty: environment.APIUrl + 'EaseRent/cms/getAllProperties'
  },
  ServiceRequest: {
    vendors: environment.APIUrl + 'EaseRent/sr/vendors',
    tickets: environment.APIUrl + 'EaseRent/sr/tickets',
    estimates: environment.APIUrl + 'EaseRent/sr/estimates',
    categories: environment.APIUrl + 'EaseRent/sr/categories',
    serviceDescription: environment.APIUrl + 'EaseRent/sr/sr-descr-item',
    saveTicketVerify :  environment.APIUrl + 'EaseRent/sr/verifyTicket',
    getAllTicketVerify : environment.APIUrl + 'EaseRent/sr/verifyTicket/by',
    saveTicketStatus :  environment.APIUrl + 'EaseRent/sr/ticketStatus',
    getAllTicketStatus : environment.APIUrl + 'EaseRent/sr/ticketStatus',
    shareReviewByEmail: environment.APIUrl + 'EaseRent/sr/shareReviewByEmail',
    ticketStatusUpdate: environment.APIUrl + 'EaseRent/sr/ticketStatusUpdate',
    url: environment.APIUrl + 'EaseRent/sr/url',
    urlById: environment.APIUrl + 'EaseRent/sr/url/byId',
    approvedStatus: environment.APIUrl + 'EaseRent/sr/approvedStatus',
    shareApprovalByEmail: environment.APIUrl + 'EaseRent/sr/shareApprovalByEmail',
    getApprovalDetails: environment.APIUrl + 'EaseRent/sr/getApprovalDetails',
    getShareUrlDetails: environment.APIUrl + 'EaseRent/sr/getShareUrlDetails',
    getVendorDetails: environment.APIUrl + 'EaseRent/sr/getVendorDetails',
    saveNewTask: environment.APIUrl + 'EaseRent/sr/saveNewTask',
    getTicketTask: environment.APIUrl + 'EaseRent/sr/getTicketTask',
    deleteTask: environment.APIUrl + 'EaseRent/sr/deleteTask',
    saveGstAmount: environment.APIUrl + 'EaseRent/sr/saveGstAmount',
    saveTaskStatusDocument: environment.APIUrl + 'EaseRent/sr/saveTaskStatusDocument',
    getTicketByPropertyIdAndDateRange: environment.APIUrl + 'EaseRent/sr/getTicketByPropertyIdAndDateRange',
    sendEmailForTicketShare: environment.APIUrl + 'EaseRent/sr/sendEmailForTicketShare',
    addVersionEstimation: environment.APIUrl + 'EaseRent/sr/addVersionEstimation',
    getVersionInfoByTicketId: environment.APIUrl + 'EaseRent/sr/getVersionInfoByTicketId',
    deleteVersion: environment.APIUrl + 'EaseRent/sr/deleteVersion',
    deleteEstimateImage: environment.APIUrl + 'EaseRent/sr/deleteEstimateImage',
    getVersionHistory: environment.APIUrl + 'EaseRent/sr/getVersionHistory',
    setCurrentVersion: environment.APIUrl + 'EaseRent/sr/setCurrentVersion',
    updateCurrentVersion: environment.APIUrl + 'EaseRent/sr/updateCurrentVersion',
  },
  
  Partner: {
    userEmails: environment.APIUrl + 'EaseRent/partner/userEmails',
    settings: environment.APIUrl + 'EaseRent/partner/settings',
  },
  DashBoard: {
    statusCounting: environment.APIUrl + 'EaseRent/dashboard/statusCounting',
    getAllManager: environment.APIUrl + 'EaseRent/dashboard/getAllManager',
    getAllManagerAndTeleCaller: environment.APIUrl + 'EaseRent/dashboard/getAllManagerAndTeleCaller',
    propertyPendingStatus: environment.APIUrl + 'EaseRent/dashboard/propertyPendingStatus',
    serviceRequestStatus: environment.APIUrl + 'EaseRent/dashboard/serviceRequestStatus',
    getAllPropertyByType: environment.APIUrl + 'EaseRent/dashboard/getAllPropertyByType',
    getTask: environment.APIUrl + 'EaseRent/dashboard/getTask',
    socialSharing: environment.APIUrl + 'EaseRent/dashboard/socialSharing',
    leadCounting: environment.APIUrl + 'EaseRent/dashboard/leadCounting',
    getBrokerageFeeDetails: environment.APIUrl + 'EaseRent/dashboard/getBrokerageFeeDetails',
    getPropertyShareDetails: environment.APIUrl + 'EaseRent/dashboard/getPropertyShareDetails',
    getPropertyClickDetails: environment.APIUrl + 'EaseRent/dashboard/getPropertyClickDetails',
    getInquiryTask: environment.APIUrl + 'EaseRent/dashboard/getInquiryTask',
    getPropertyByUserId: environment.APIUrl + 'EaseRent/dashboard/getPropertyByUserId',
    getShareCount: environment.APIUrl + 'EaseRent/dashboard/getShareCount',
    getPropertyClickedCount: environment.APIUrl + 'EaseRent/dashboard/getPropertyClickedCount',
    getPropertyClickedFromShare: environment.APIUrl + 'EaseRent/dashboard/getPropertyClickedFromShare',
    updateLeadTaskSchedule: environment.APIUrl + 'EaseRent/dashboard/updateLeadTaskSchedule',
  },


  Platforms: {
    allPlatforms: environment.APIUrl + 'EaseRent/all-platforms',
    propertyPlatforms: environment.APIUrl + 'EaseRent/property-platforms',

  },
  Inquiry: {
    Add: environment.APIUrl + 'EaseRent/createInquiry',
    Inquiries: environment.APIUrl + 'EaseRent/inquiries',
    Properties: environment.APIUrl + 'EaseRent/getProperties',
    GetInquiryById: environment.APIUrl + 'EaseRent/getInquiryById',
    ShareInquiries: environment.APIUrl + 'EaseRent/shareInquiries',
    MatchProperties: environment.APIUrl + 'EaseRent/getMatchedProperties',
    PlatformMatchProperties: environment.APIUrl + 'EaseRent/v2/getMatchedProperties',
    GetStatus: environment.APIUrl + 'EaseRent/getInquiryStatus',
    AddInquiryAction: environment.APIUrl + 'EaseRent/addInquiryAction',
    GetInquiryAction: environment.APIUrl + 'EaseRent/getInquiryAction',
    GetInquiryActionByInquiryId: environment.APIUrl + 'EaseRent/getInquiryActionByInquiryId',
    GetInquiryActionByPropertyId: environment.APIUrl + 'EaseRent/getInquiryActionByPropertyId',
    AddInquiryComment: environment.APIUrl + 'EaseRent/addInquiryComment',
    GetInquiryDetailsByInquiryId: environment.APIUrl + 'EaseRent/getInquiryDetailsByInquiryId',
    GetAllInquiries: environment.APIUrl + 'EaseRent/getInquiryAction',
    UpdateInquiries: environment.APIUrl + 'EaseRent/updateInquiries',
    UpdateLeadStatus: environment.APIUrl + 'EaseRent/updateLeadStatus',
    updateLeadActivity: environment.APIUrl + 'EaseRent/updateLeadActivity',
    addLeadComment: environment.APIUrl + 'EaseRent/addLeadComment',
    getLeadComment: environment.APIUrl + 'EaseRent/getLeadComment',
    getLeadCommentByIId: environment.APIUrl + 'EaseRent/getLeadCommentByInquiryIds',
    updateBulkInquiries: environment.APIUrl + 'EaseRent/updateBulkInquiries',
    getInquiryByProperty: environment.APIUrl + 'EaseRent/getInquiryByProperty',
    getAssignLead: environment.APIUrl + 'EaseRent/getAssignLead',
    getMatchedLeads: environment.APIUrl + 'EaseRent/getMatchedLeads',
    getInquiryByPage: environment.APIUrl + 'EaseRent/getInquiryByPage',
    getCount: environment.APIUrl + 'EaseRent/getCount',
    getInquiryByInquiryId: environment.APIUrl + 'EaseRent/getInquiryByInquiryId',
    getTaskValues: environment.APIUrl + 'EaseRent/getTaskValues',
    getLeadStatusCount: environment.APIUrl + 'EaseRent/getLeadStatusCount',
    deleteInquiries: environment.APIUrl + 'EaseRent/deleteInquiries',
    localities: environment.APIUrl + 'EaseRent/inquiries/locality/all',
    getSuggestedProperties:  environment.APIUrl + 'EaseRent/getSuggestedProperties',
  },

  Task: {
    getTaskSubTypes: environment.APIUrl + 'EaseRent/getTaskSubTypes',
    saveTaskConfiguration: environment.APIUrl + 'EaseRent/saveTaskConfiguration',
    getTaskConfigured: environment.APIUrl + 'EaseRent/getTaskConfigured',
    getTaskRulesInitiator: environment.APIUrl + 'EaseRent/getTaskRulesInitiator',
    getScheduledTasks: environment.APIUrl + 'EaseRent/getScheduledTasks',
    getTaskSubType: environment.APIUrl + 'EaseRent/getTaskSubType',
    getTaskStatusCount: environment.APIUrl + 'EaseRent/getTaskStatusCount',
    updateActionStatus: environment.APIUrl + 'EaseRent/updateActionStatus',
    getScheduledTasksforAdmin: environment.APIUrl + 'EaseRent/getScheduledTasksforAdmin',
    getAllPMTaskStatusCount: environment.APIUrl + 'EaseRent/getAllPMTaskStatusCount',
    savePropertyTaskConfiguration: environment.APIUrl + 'EaseRent/savePropertyTaxTaskConfiguration',
    saveServiceRequestTaskConfiguration: environment.APIUrl + 'EaseRent/saveServiceRequestTaskConfiguration',
    saveInspectionTaskConfiguration: environment.APIUrl + 'EaseRent/saveInspectionTaskConfiguration',
    getNumberOfDays: environment.APIUrl + 'EaseRent/getNumberOfDays',
    createATaskByPM: environment.APIUrl + 'EaseRent/createATaskByPM',
    getTasksCreateByPM:environment.APIUrl + 'EaseRent/getTasksCreateByPM',
    reminderAutoInitiatorEnable: environment.APIUrl + 'EaseRent/reminderAutoInitiatorEnable',
    getRoleList: environment.APIUrl + 'EaseRent/getRoleList',
    deleteTaskRule: environment.APIUrl + 'EaseRent/deleteTaskRule',
    getAllTaskForMaintenanceManager: environment.APIUrl + 'EaseRent/getAllTaskForMaintenanceManager',
    getTaskRulesSetUp:environment.APIUrl + 'EaseRent/getTaskRulesSetUp'
  },
  
  Inspection: {
    addInspection: environment.APIUrl + 'EaseRent/inspection',
    updateInspection : environment.APIUrl + 'EaseRent/inspection',
    updateInspectionStatus : environment.APIUrl + 'EaseRent/inspection/status',
    changeAdhocToggle : environment.APIUrl + 'EaseRent/inspection/changeAdhocToggle',
    getInspections : environment.APIUrl + 'EaseRent/inspection/filter',
    getInspectionsPropertires : environment.APIUrl + 'EaseRent/inspection/properties/filter',
    getDoneInspectionsPropertires : environment.APIUrl + 'EaseRent/inspection/properties/done/filter',
    getInspectionById: environment.APIUrl + 'EaseRent/inspection/byId',
    addInspectionItem: environment.APIUrl + 'EaseRent/inspection/item',
    updateInspectionItem: environment.APIUrl + 'EaseRent/inspection/item/byId',
    addInspectionSection: environment.APIUrl + 'EaseRent/inspection/section',
    updateInspectionSection: environment.APIUrl + 'EaseRent/inspection/section/byId',
    getInspectionItem: environment.APIUrl + 'EaseRent/inspection/item/bySection',
    updateItemRepairCost : environment.APIUrl + 'EaseRent/inspection/item/repairCosts/byId',
    deleteInpectionItem: environment.APIUrl + 'EaseRent/inspection/deleteItem/byId',
    addInspectionRule: environment.APIUrl + 'EaseRent/inspection/inspectionRule',
    updateInspectionRule : environment.APIUrl + 'EaseRent/inspection/inspectionRule',
    getAllInspectionRules : environment.APIUrl + 'EaseRent/inspection/inspectionRule/filter',
    deleteInspectionRule : environment.APIUrl + 'EaseRent/inspection/inspectionRule/deleteById',
    getInspectionRuleById : environment.APIUrl + 'EaseRent/inspection/inspectionRule/byId',
    saveTemplate : environment.APIUrl + 'EaseRent/inspectionTemplate',
    getTemplate : environment.APIUrl + 'EaseRent/inspectionTemplate/byId',
    getTemplates : environment.APIUrl + 'EaseRent/inspectionTemplate/filter',
    deleteTemplate : environment.APIUrl + 'EaseRent/inspectionTemplate',
    updateTemplate: environment.APIUrl + 'EaseRent/inspectionTemplate',
    saveSection : environment.APIUrl + 'EaseRent/inspectionTemplate/section',
    updateSection : environment.APIUrl + 'EaseRent/inspectionTemplate/section',
    deleteSection : environment.APIUrl + 'EaseRent/inspectionTemplate/section',
    saveItem : environment.APIUrl + 'EaseRent/inspectionTemplate/item',
    updateItem : environment.APIUrl + 'EaseRent/inspectionTemplate/item',
    deleteItem : environment.APIUrl + 'EaseRent/inspectionTemplate/item',
    sendingEmailByInspectionId :environment.APIUrl + 'EaseRent/inspection/sendingEmailById',
    verifyInspectionByOtp : environment.APIUrl + 'EaseRent/inspection/verify',
    checkInspectionOtp : environment.APIUrl + 'EaseRent/inspection/verifyOtp',
    saveInspectionAutoInitiator : environment.APIUrl + 'EaseRent/inspection/inspectionAutoInitiator',
    updateInspectionAutoInitiator : environment.APIUrl + 'EaseRent/inspection/inspectionAutoInitiator',
    deleteInspectionAutoInitiator : environment.APIUrl + 'EaseRent/inspection/deleteAdhocRule',
    getInspectionAutoInitiator : environment.APIUrl + 'EaseRent/inspection/inspectionAutoInitiator',
    inspectionAutoInitiatorEnable : environment.APIUrl + 'EaseRent/inspection/inspectionAutoInitiator/enable',
    deleteDoc : environment.APIUrl + 'EaseRent/inspection',
    getInspectionByPropertyIdAndInspectionTypeId: environment.APIUrl + 'EaseRent/inspection/getInspectionByPropertyIdAndInspectionTypeId',
    shareInspection: environment.APIUrl + 'EaseRent/inspection/shareInspection',
  },

  TenantInformation: {
    saveTenant: environment.APIUrl + 'EaseRent/tenantInformation',
    getAllTenant: environment.APIUrl + 'EaseRent/tenantInformation/getAll/filter',
    getTenantById: environment.APIUrl + 'EaseRent/tenantInformationById',
    getTenant: environment.APIUrl + 'EaseRent/tenantInformation/byId',
    updateTenant: environment.APIUrl + 'EaseRent/tenantInformation',
    deleteTenant: environment.APIUrl + 'EaseRent/tenantInformation/deleteTenant',
    saveTenantWorkInfo: environment.APIUrl + 'EaseRent/tenantInformation/workInfo',
    getAllDocuments: environment.APIUrl + 'EaseRent/tenantInformation/getAllDocuments',
    updateTenantWorkInfo: environment.APIUrl + 'EaseRent/tenantInformation/workInfo',
    getTenantWorkInfo: environment.APIUrl + 'EaseRent/tenantInformation/workInfo/byId',
    deleteTenantWorkInfo: environment.APIUrl + 'EaseRent/tenantInformation/workInfo/delete',
    getAllTenantWorkInfo: environment.APIUrl + 'EaseRent/tenantInformation/workInfo/getAll',
    saveTenantAddressInfo: environment.APIUrl + 'EaseRent/tenantInformation/addressInfo',
    updateTenantAddressInfo: environment.APIUrl + 'EaseRent/tenantInformation/addressInfo',
    getTenantAddressInfo: environment.APIUrl + 'EaseRent/tenantInformation/addressInfo/byId',
    getAllTenantAddressInfo: environment.APIUrl + 'EaseRent/tenantInformation/addressInfo/getAll',
    deleteTenantAddressInfo: environment.APIUrl + 'EaseRent/tenantInformation/addressInfo/delete',
    getAllTenantCityAndProperty: environment.APIUrl + 'EaseRent/tenantInformation/tenantCityAndProperty'
  },

  OwnerInformation: {
    getAllOwner: environment.APIUrl + 'EaseRent/ownerInformation/getAllOwner/filter',
    saveOwnerInfo:  environment.APIUrl + 'EaseRent/ownerInformation',
    getOwnerById: environment.APIUrl + 'EaseRent/ownerInformation/byId',
    updateOwnerInfo :  environment.APIUrl + 'EaseRent/ownerInformation',
    deleteOwner: environment.APIUrl + 'EaseRent/ownerInformation/deleteOwner',
    getAllDocuments :  environment.APIUrl + 'EaseRent/ownerInformation/getAllDocuments',
    saveOwnerWorkInfo: environment.APIUrl + 'EaseRent/ownerInformation/workInfo',
    updateOwnerWorkInfo: environment.APIUrl + 'EaseRent/ownerInformation/workInfo',
    getOwnerWorkInfo: environment.APIUrl + 'EaseRent/ownerInformation/workInfo/byId',
    deleteOwnerWorkInfo: environment.APIUrl + 'EaseRent/ownerInformation/workInfo/delete',
    getAllOwnerWorkInfo: environment.APIUrl + 'EaseRent/ownerInformation/workInfo/getAll',
    saveOwnerAddressInfo: environment.APIUrl + 'EaseRent/ownerInformation/addressInfo',
    updateOwnerAddressInfo: environment.APIUrl + 'EaseRent/ownerInformation/addressInfo',
    getOwnerAddressInfo: environment.APIUrl + 'EaseRent/ownerInformation/addressInfo/byId',
    deleteOwnerAddressInfo: environment.APIUrl + 'EaseRent/ownerInformation/addressInfo/delete',
    getAllOwnerAddressInfo: environment.APIUrl + 'EaseRent/ownerInformation/addressInfo/getAll',
    getAllOwnerCityAndProperty: environment.APIUrl + 'EaseRent/ownerInformation/ownerCityAndProperty',
    getLocalPersonByOwner: environment.APIUrl + 'EaseRent/ownerInformation/getLocalPersonByOwner',
    saveLocalPerson: environment.APIUrl + 'EaseRent/ownerInformation/saveLocalPerson',
    deleteLocalPerson: environment.APIUrl + 'EaseRent/ownerInformation/personInfo/delete'
  },

  Admin: {
    saveMainMenu: environment.APIUrl + 'EaseRent/saveMainMenu',
    saveSubMenu: environment.APIUrl + 'EaseRent/saveSubMenu',
    getMenu:environment.APIUrl + 'EaseRent/getMenu',
    getSubMenu:environment.APIUrl + 'EaseRent/getSubMenu',
    getAllRoles: environment.APIUrl + 'EaseRent/getAllRoles',
    getSubMenuById: environment.APIUrl + 'EaseRent/getSubMenuById',
    saveMenuRoleMaster: environment.APIUrl + 'EaseRent/saveMenuRoleMaster',
    getMenuRoleMasterByRole: environment.APIUrl + 'EaseRent/getMenuRoleMasterByRole',
    getAllAsignedByPartnerId: environment.APIUrl + 'EaseRent/getAllAsignedByPartnerId',
    updateMenuRoleMaster: environment.APIUrl + 'EaseRent/updateMenuRoleMaster',
    getAllPartnerId: environment.APIUrl + 'EaseRent/getAllPartnerId',
    getLandingPage: environment.APIUrl + 'EaseRent/getLandingPage',
    getAllModulesByPartnerIdAndRoleId: environment.APIUrl + 'EaseRent/getAllModulesByPartnerIdAndRoleId',
  },

  Other: {
    generateDynamicReport: environment.APIUrl + 'EaseRent/generateDynamicReport',
    generate: environment.APIUrl + 'EaseRent/generate',
    download: environment.APIUrl + 'EaseRent/download',
    approvedStatus: environment.APIUrl + 'EaseRent/approvedStatus',
  },
  Invoice: {
    createInvoice: environment.APIUrl + 'EaseRent/invoice/createInvoice',
    saveInvoiceInformation: environment.APIUrl + 'EaseRent/invoice/saveInvoiceInformation',
    getInvoiceInformation: environment.APIUrl + 'EaseRent/invoice/getInvoiceInformation',
    userBankDetails: environment.APIUrl + 'EaseRent/invoice/userBankDetails',
    getUserBankAccount: environment.APIUrl + 'EaseRent/invoice/getUserBankAccount',
    getInvoiceByPropertyId: environment.APIUrl + 'EaseRent/invoice/getInvoiceByPropertyId',
    getInvoiveByInvoiceId: environment.APIUrl + 'EaseRent/invoice/getInvoiveByInvoiceId',
    createInvoiceOpenUrl: environment.APIUrl + 'EaseRent/invoice/createInvoiceOpenUrl',
    deleteInvoiceById: environment.APIUrl + 'EaseRent/invoice/deleteInvoiceById',
    addOtherUser: environment.APIUrl + 'EaseRent/invoice/addOtherUser',
    deleteInvoiceDetail:environment.APIUrl +'EaseRent/invoice/deleteInvoiceDetail',
    getInvoiceDetailById: environment.APIUrl + 'EaseRent/invoice/getInvoiceDetailById',
    generateInvoice: environment.APIUrl + 'EaseRent/invoice/generateInvoice',
    getParamMasterForInvoice: environment.APIUrl + 'EaseRent/invoice/getParamMasterForInvoice',
    saveInvoiceUserDetail: environment.APIUrl + 'EaseRent/invoice/saveInvoiceUserDetail',
    saveInvoiceDetail: environment.APIUrl + 'EaseRent/invoice/saveInvoiceDetail',
    addGstForUsersAtInvoice: environment.APIUrl + 'EaseRent/invoice/addGstForUsersAtInvoice',
    getInvoiceOpenUrlData: environment.APIUrl + 'EaseRent/invoice/getInvoiceOpenUrlData',
    getLatestInvoice: environment.APIUrl + 'EaseRent/invoice/getLatestInvoice'
  },

  Common: {
    setUpMarkPayments: environment.APIUrl + 'EaseRent/setUpMarkPayments',
    deleteDocById:environment.APIUrl + 'EaseRent/deleteDocById',
    getAllPropertyForModules: environment.APIUrl + 'EaseRent/getAllPropertyForModules',
    setDocumentToIdentityId: environment.APIUrl + 'EaseRent/setDocumentToIdentityId',
    getPropertyUserList: environment.APIUrl + 'EaseRent/getPropertyUserList'
  },
  
  Report: {
    getGeneratedReports: environment.APIUrl + 'EaseRent/getGeneratedReports',
    getReportDetailsById: environment.APIUrl + 'EaseRent/getReportDetailsById',
    addReportOpenUrl: environment.APIUrl + 'EaseRent/addReportOpenUrl',
    updateReportStatus: environment.APIUrl + 'EaseRent/updateReportStatus',
    reportShareApprovalByEmail: environment.APIUrl + 'EaseRent/reportShareApprovalByEmail',
    generatePdfData: environment.APIUrl + 'EaseRent/generatePdfData',
  } ,

  Archive: {
    setArciveProperty: environment.APIUrl + 'Easrent/archive/setArciveProperty',
    archiveDoucmentByProperty: environment.APIUrl + 'Easrent/archive/archiveDoucmentByProperty'
  }

};
